/* ======================================================================== */
/* 27. postPreview */
/* ======================================================================== */
.post-preview__wrapper-content
	> *:first-child
		margin-top: 0
.post-preview__date
	font-family: $font-secondary
	font-size: 16px
	font-weight: 500
	line-height: 1.5
	letter-spacing: 1px
	text-transform: uppercase
.post-preview__header, .post-preview__media, .post-preview__date
	a
		background-image: none
.post-preview__header
	border-bottom: 1px solid $borders-dark
	margin-bottom: 2em
	h2
		margin-top: 0.75em
		margin-bottom: 0.75em
.post-preview__categories
	+reset-ul
	li
		display: inline-block
.post-preview__meta
	margin-bottom: 1em
.post-preview__wrapper-meta
	margin-top: 5px
.post-preview__wrapper-readmore
	text-align: right
	margin-top: 2em
