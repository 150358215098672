/* ======================================================================== */
/* 54. tags */
/* ======================================================================== */
.tagcloud
	ul
		+reset-ul
		li
			display: inline-block !important
			margin-bottom: 0 !important
	a
		display: inline-block
		font-size: 12px !important
		line-height: 1
		color: $dark
		background-color: $very-light-pink
		padding: 11px 15px
		margin-bottom: 6px
		margin-right: 4px
		border-radius: 100px
		background-image: none
		&:hover
			background-color: $light-blue-grey
			opacity: 1
