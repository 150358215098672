/* ======================================================================== */
/* 50. sliderHeadings */
/* ======================================================================== */
.slider-headings
	text-align: center
	height: 100%
	.slider__dot
		&:before
			background-color: $dark
		&:after
			border-color: $dark
.slider-headings__footer
	position: absolute
	left: 0
	right: 0
	bottom: 30px
	margin: 0 auto
	text-align: center
	z-index: 60
.slider-headings__slide
	display: flex
	align-items: center
	justify-content: center
	h2
		font-size: 68px
.slider-headings__link
	display: block
	padding-right: 15px
	padding-left: 15px
	background-image: none
	&:hover
		opacity: 1
		color: $white
.slider-headings__progress
	position: absolute
	top: 50%
	left: 50%
	transform: translate(-50%, -50%)
	font-weight: bold
	z-index: -1
	opacity: .05
	height: 300px
	margin-top: 0
	+trans1
.slider-headings__counter
	font-size: 300px
	line-height: 300px
	height: 300px
	overflow: hidden
.slider-headings__arrow
	position: absolute
	top: 50%
	transform: translateY(-50%)
	z-index: 60
.slider-headings__arrow_prev
	left: 80px
.slider-headings__arrow_next
	right: 80px

@media screen and (max-width: $xxl)
	.slider-headings__arrow_prev
		left: 45px
	.slider-headings__arrow_next
		right: 45px
	.slider-headings__slide
		h2
			font-size: 54px
	.slider-headings__counter
		font-size: 250px
		line-height: 250px
		height: 250px

@media screen and (max-width: $xl)
	.slider-headings__arrow_prev
		left: 25px
	.slider-headings__arrow_next
		right: 25px

@media screen and (max-width: $md)
	.slider-headings__slide
		h2
			font-size: 28px
			line-height: 1.3
	.slider-headings__arrow
		&:after
			transform: scale(1) !important
	.slider-headings__arrow_prev
		left: 30px
	.slider-headings__arrow_next
		right: 30px
	.slider-headings__counter
		font-size: 150px
		line-height: 150px
		height: 150px

@media screen and (max-width: $sm)
	.slider-headings__arrow
		display: none
