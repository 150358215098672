/* ======================================================================== */
/* 21. inputFloat */
/* ======================================================================== */
.input-float
	position: relative
	display: block
	width: 100%
	margin-bottom: 0
.input-float__label
	position: absolute
	top: 10px
	left: 0
	margin: auto
	display: block
	font-size: 16px
	@include trans1
	transform-origin: left center
	cursor: text
	color: $brown-grey-2
.input-float__input
	display: block
	width: 100%
	width: 100%
	border-bottom: 1px solid $borders-dark
	border-top: none
	border-right: none
	border-left: none
	outline: none
	padding: 12px 0 8px
	+trans1
	background-color: transparent
	border-radius: 0
	color: inherit
.input-float__input_textarea
	resize: none
	min-height: 200px

.input-float__input_focused, .input-float__input_not-empty
	+ .input-float__label
		transform: scale(0.8571) translateY(-1.7rem)

.input-float__input_not-empty
	border-color: $borders-dark
